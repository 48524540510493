.header-top {
  /* background-color: #39a0c5; */
  padding: 5px 0px;
  border-bottom: 4px solid #ff4747;

}

.header-top .top-left a {
  text-decoration: none;
  /* color: rgb(255, 255, 255); */
  margin-right: 12px;
  font-size: 15px;
  font-weight: 300;
  line-height: 23px;
}

.header-top .top-left a:hover {
  color: #B9F8D3;
  transition: 1s ease;
}

.header-top .top-right .social-media ul {
  display: inline;
}

.header-top .top-right .social-media ul li {
  list-style-type: none;
  display: inline-block;
}

.header-top .top-right .social-media a {
  text-decoration: none;
  /* color: rgb(248, 248, 248); */
  font-size: 15px;
  padding-left: 12px;
  font-weight: 400;
}

.header-top .top-right .social-media a i {
  font-size: 15px;
}

.header-top .top-right .social-media a:hover {
  transition: 1s ease;
}

.header-top .top-right .social-media .facebook:hover {
  color: #3B5998;
}

.header-top .top-right .social-media .twitter:hover {
  color: #7ed3f7;
}

.header-top .top-right .social-media .linkedin:hover {
  color: #0665d2;
}

.header-top .top-right .social-media .instagram:hover {
  color: #CF2158;
}

.header-top .top-right .social-media .pinterest:hover {
  color: #C41F26;
}

.header-top .top-right .social-media .youtube:hover {
  color: #e92b2b;
}

@media (max-width: 575.98px) {
  .header-top {
    display: none;
  }
}