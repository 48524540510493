.commingSoon_popUp{
    overflow: hidden;
}
.commingSoon_popUp .modal-content {
    background-color: #e6e6e6!important;
}
.commingSoon_popUp .modal {
    top: 10px;
}
.commingSoon_popUp .modal-header {
    border-bottom: none !important;
}
.commingSoon_popUp .modal-dialog {
    max-width: 800px !important;
}
.commingSoon_popUp .pop_text{
    font-size: 30px;
   color: rgb(36, 1, 1);
    letter-spacing: 1.5px;
    font-family: italic;
    font-weight: 600;
    text-shadow: 2px 2px #FF0000;
}
.commingSoon_popUp .pop_img img{
    height: 150px;
    width: 100%;
    border-radius: 10px;
    margin: auto;
    display: block;
}