.special_services_section {
    overflow: hidden;
}

.special_services_section .hoverble_img {
    height: 35px;
    width: 35px;
    transition: all 1s ease;
}

.special_services_section .custom_padding {
    padding: 15px 8px;
}

.special_services_section .hoverle_card p {
    font-size: 14px;
    font-weight: 400;
}

.special_services_section .hoverle_card:hover {
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 8px 0 rgba(0, 0, 0, 0.19);
    transition: all 00.2s ease;
}

.special_services_section .hoverle_card:hover .hoverble_img {
    height: 55px;
    width: 55px;
    transform: rotate(360deg);
    transition: all 1s ease;
}



/* view spacial services css */
.view_special_service .container {
    max-width: 1060px !important;
}

.view_special_service .view_service_plan .container {
    max-width: 1160px !important;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400&display=swap');



.view_special_service .view_thembnail {
    position: relative;
    overflow: hidden;
    display: block;
}

.view_special_service .view_thembnail::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    transform: scaleY(0);
    opacity: .7;
    z-index: 1;
    transition: all .5s ease;
    background: linear-gradient(to right, #00c3ff 0%, rgba(233, 71, 165, 0.1) 100%);
    transform-origin: bottom;
}

.view_special_service .spacial_thumb_card:hover .view_thembnail::before {
    transform: scaleY(1);
    transition: all .5s ease;
}

.view_special_service .services_img {
    position: absolute;
    z-index: 99;
    background: #eaf9ff;
    text-align: center;
    color: #fff;
    font-size: 40px;
    top: 200px;
    right: 20px;
    border-radius: 4px;
    width: 70px;
    height: 70px;
    line-height: 70px;
    -webkit-transition: all .5s ease-in-out 0s;
    -moz-transition: all .5s ease-in-out 0s;
    -ms-transition: all .5s ease-in-out 0s;
    -o-transition: all .5s ease-in-out 0s;
    transition: all .5s ease-in-out 0s;
}

.view_special_service .spacial_thumb_card:hover .services_img {
    background: #eaf9ff;
    transform: translateX(-110px) translateY(-100px);
    -webkit-transition: all .5s ease-in-out 0s;
    -moz-transition: all .5s ease-in-out 0s;
    -ms-transition: all .5s ease-in-out 0s;
    -o-transition: all .5s ease-in-out 0s;
    transition: all .5s ease-in-out 0s;
}





/* service plan css */

.view_special_service .service_plan_wrap .title {
    color: #5b5c5e;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}

.view_special_service .service_plan_wrap .plan_name {
    font-size: 20px;
    text-align: center;
    font-weight: 500;
    padding-top: 10px;
    letter-spacing: 1px;
    color: white;
    background-color: #00c3ff !important;
    background-image: linear-gradient(to right, #00c3ff 0%, #ea7c87 100%);
    height: 45px;


}

.view_special_service .custom_hover:hover {
    border: 2px solid #ff4747;
}

.view_special_service .custom_hover:hover .service_plan_wrap .plan_name {
    background-color: #ff4747 !important;
    background-image: linear-gradient(to right, #ff4747 0%, #ff4747 100%);
    -webkit-transition: all .5s ease-in-out 0s;
    -moz-transition: all .5s ease-in-out 0s;
    -ms-transition: all .5s ease-in-out 0s;
    -o-transition: all .5s ease-in-out 0s;
    transition: all .5s ease-in-out 0s;
}



.view_special_service .service_plan_wrap .custom_bg {
    background-color: #f7f6fb;
    height: 160px;
}





.view_special_service .plan_context ul li {
    list-style-type: disc;
    color: #6b6b6b;
    padding: 4px 0px;
    font-size: 15px;
    font-weight: 500;
    line-height: normal;
    font-family: 'Raleway', sans-serif;
}

.view_special_service .plan_context .plan_price {
    font-family: 'Raleway', sans-serif;
}

.view_special_service .plan_context .cutom_btn {
    outline: none;
    padding: 10px 35px;
    border: none;
    background-color: #00c3ff;
    color: white;
    border-radius: 25px;
    margin: auto;
    display: block;
    text-align: center;
}

.view_special_service .plan_context .cutom_btn:hover {
    background-color: white;
    color: #0c2139;
    border: 2px solid #ff4747;
}










/* service bottom animaion css */
.view_special_service .view_service_bottom_animation {
    position: relative;
    padding: 20px 0 200px;
    overflow: hidden;
}

.view_special_service .view_service_bottom_animation .bottom_bg {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 266px;

}

.view_special_service .bottom_bg .bg_one {

    background-size: auto;
    width: 330px;
    height: 105px;
    background-size: 100% !important;
    position: absolute;
    bottom: 0;
    left: 30%;
    -webkit-animation: myfirst 22s linear infinite;
    animation: myfirst 22s linear infinite;
}

.view_special_service .bottom_bg .bg_two {
    background-size: auto;

    background-size: 100% !important;
    bottom: 0;
    left: 38%;
    position: absolute;
    -webkit-animation: myfirst 25s linear infinite;
    animation: myfirst 25s linear infinite;
}

@keyframes myfirst {
    from {
        left: 0%;
    }

    to {
        left: 100%;
    }
}