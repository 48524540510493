.blog_mega_menu .dropdown-hover:hover>.dropdown-menu {
    display: inline-block;
    }
    
.blog_mega_menu .dropdown-hover>.dropdown-toggle:active {
    /*Without this, clicking will make it sticky*/
    pointer-events: none;
    }

.blog_mega_menu.custom_sticky {
        top: 75px !important;
        position: sticky;
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 0px;
    z-index: 990;
}
.blog_mega_menu .navbar {
  padding: 20px !important;
}
.blog_mega_menu .bg-light {
  background-color: #E0E0E0 !important;
}
.w-100 {
    width: fit-content !important;
  }
.blog_mega_menu .dropdown-menu {
    background-color: #E0E0E0;
    border: 1px solid rgba(0,0,0,.15);

  }
  .blog_mega_menu .sub-menu-item {
    list-style: none;
    padding: 4px 20px;
    margin-left: 10px;
    position: relative;
  }

.blog_mega_menu  .sos-catagory {
    position: absolute;
    left: 149px;
    padding: 5px 5px;
    top: 1px;
    bottom: 0; 
    z-index: 995;
    min-width: 160px !important;
    /* max-width: 1000px !important; */
    visibility: hidden;
    opacity: 0;
    display: none;
    background-color: #EAE6E6;
    height: -moz-fit-content !important;
    height: fit-content !important;
    border-radius: 3px;
    z-index: 999;
    transition: all 2s ease;
  }

.blog_mega_menu .sub-menu-item:hover .sos-catagory {

    visibility: visible;
    opacity: 1;
    display: block;
    overflow: hidden;
    transition: all 2s ease;
  }

  .blog_mega_menu .sub-menu-item .hover_effect:hover{
    color: #ff4747 !important;
    margin-left: 10px;
    transition: 0.9s ease;
}

.blog_mega_menu .sos-catagory li{
 list-style: none;
}