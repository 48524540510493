.blog_views .post_img{
    height: 330px !important;
}
.blog_views .post_info span{
    font-size: 16px;
    color: rgb(0, 0, 0);
}
.blog_views .post_info i{
    font-size: 16px;
}
.blog_views .card-title p{
    font-size: 20px;
    color: #2d3542;
    font-weight: 500;
    margin-bottom: 2px;
}
@media (max-width: 991.98px) { 
    .blog_views .post_img{
        height: 200px !important;
    }
}