body {
  background: white center center / cover no-repeat fixed;
  height: 200vh;
}



/* Preloader */
.ctn-preloader {
  align-items: center;
  cursor: none;
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.ctn-preloader #loading-center {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 9999;
}

.ctn-preloader #loading-center-absolute {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 200px;
  width: 200px;
  margin-top: -100px;
  margin-left: -100px;

}

.object {
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
  position: absolute;
  border-left: 5px solid red;
  border-right: 5px solid red;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  -webkit-animation: animate 2s infinite;
  animation: animate 2s infinite;
}

.ctn-preloader #object_one {
  left: 75px;
  top: 75px;
  width: 50px;
  height: 50px;
}

.ctn-preloader #object_two {
  left: 65px;
  top: 65px;
  width: 70px;
  height: 70px;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.ctn-preloader #object_three {
  left: 55px;
  top: 55px;
  width: 90px;
  height: 90px;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.ctn-preloader #object_four {
  left: 45px;
  top: 45px;
  width: 110px;
  height: 110px;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;

}

@-webkit-keyframes animate {


  50% {
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  100% {
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

}

@keyframes animate {

  50% {
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  100% {
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }


}

/* .ctn-preloader .animation-preloader {
  position: absolute;
  z-index: 100;
} */


.ctn-preloader .loader-section {
  background-color: #ffffff;
  height: 100%;
  position: fixed;
  top: 0;
  width: calc(50% + 1px);
}

.ctn-preloader .loader-section.section-left {
  left: 0;
}

.ctn-preloader .loader-section.section-right {
  right: 0;
}

/* Efecto de fade en la animación de cargando */
.loaded .animation-preloader {
  opacity: 0;
  transition: 0.3s ease-out;
}

/* Efecto de cortina */
.loaded .loader-section.section-left {
  transform: translateX(-101%);
  transition: 0.7s 0.3s all cubic-bezier(0.1, 0.1, 0.1, 1.000);
}

.loaded .loader-section.section-right {
  transform: translateX(101%);
  transition: 0.7s 0.3s all cubic-bezier(0.1, 0.1, 0.1, 1.000);
}