.marquee_style .marqee_wrapper{
    position: absolute;
    content: " ";
    height: 40px;
    width: 100px;
  padding: 6px 15px;
    color: white;
    background: linear-gradient(-180deg,#f53d2d,#f63);
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    z-index: 1;

}
.marquee_style span .marquee{
    background:none;
}
.marquee_style .marquee{
    background-color: rgb(216, 253, 253);
    margin-left: 5px;
    font-size: 14px;
    font-weight: 300;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

