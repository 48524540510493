
.news_letter  h4 {
    font-size: 24px;
    color: #333;
    font-weight: 500;
    margin-bottom: 1px !important;
}

.news_letter  span {
    font-size: 16px;
    color: rgb(73, 72, 72);
}

.news_letter .subscribe_btn {
    font-size: 15px;
    font-weight: 400;
}

.news_letter input {
    font-family: "Font Awesome 5 Free" !important;
}

.news_letter label {
    font-family: "Font Awesome 5 Free" !important;
}