.payment_method_section {
    overflow: hidden;
}

.payment_method_section .hoverble_img {
    height: 37px;
    width: 37px;
    transition: all 1s ease;
}

.payment_method_section .card_img {
    background: linear-gradient(to top left, #33cccc 18%, #ff6699 95%);
    padding: 15px 8px;
}

.payment_method_section .card_img p {
    font-size: 16px;
    font-weight: 400;
}

.payment_method_section .hoverle_card:hover {
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 8px 0 rgba(0, 0, 0, 0.19);
    transition: all 0.5s ease;
}

.payment_method_section .hoverle_card:hover .hoverble_img {
    height: 55px;
    width: 55px;
    transform: rotate(360deg);
    transition: all 1s ease;
}