.footer_copy_right {
    background: #0d313c;
    overflow: hidden;
    padding: 7px 0px;
    height: 60px;
    border-top: 1px solid #374957;
}
.footer_copy_right p {
    color: white;
    font-size: 12px;
}
.footer_copy_right p a{
    color: #39a0c5;
}
.footer_copy_right p a:hover{
    color: #ff4747;
}
.footer_copy_right img {
    width: 45px;
    height: 25px;
    padding: 0px 5px;
    margin-top: -30px;
}
@media (max-width: 576px) {
    .footer_copy_right {
        height: auto !important;
    }
}
@media (max-width: 370px) {
    .footer_copy_right {
        height: 75px;
    }
}

@media (max-width: 768px) { 
    .footer_copy_right{
        margin-bottom: 50px;
    }

}