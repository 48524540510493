.section_title_img{
    position: relative;
}
.section_title_img .sec_img{
    width: 40px !important;
    height: 40px !important;
}
.section_title_img::before{
    content: "";
    position: absolute;
    top: 50%;
    right: 43%;
    background-color: #00c3ff;
    width: 100px;
    height: 3px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    -webkit-transform: translateX(50%);
    -moz-transform: translateX(50%);
    -ms-transform: translateX(50%);
    -o-transform: translateX(50%);
    transform: translateX(50%);
   
}

.section_title_img::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 43%;
    background-color: #00c3ff;
    width: 100px;
    height: 3px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);

}
@media(max-width: 416px){
    .section_title_img::before{
        right: 30%;
        width: 60px;
    }
    
    .section_title_img::after {
        left: 30%;
        width: 60px;
    
    }
}
@media(min-width: 416px) and (max-width: 576px) { 
    .section_title_img::before{
        right: 33%;
        width: 80px;
    }
    
    .section_title_img::after {
        left: 33%;
        width: 80px;
    
    }
    
    
     }
@media(min-width: 576px) and (max-width: 768px) { 
    .section_title_img::before{
        right: 37%;
        width: 90px;
    }
    
    .section_title_img::after {
        left: 37%;
        width: 90px;
    
    }
    
    
     }
@media (min-width: 768px) and (max-width: 1100px) {
.section_title_img::before{
    right: 40%;
    width: 90px;
}

.section_title_img::after {
    left: 40%;
    width: 90px;

}


 }
