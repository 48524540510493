

.bannner_section .hoverle_img:hover {
    transform: translateY(-4px);
    transition: all 0.6s ease;
}


.bannner_section .section-title {
    font-size: 18px;
    color: #ff4747;
    position: relative;
    font-weight: 500;
}

.bannner_section .section-title::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 40%;
    background-color: #ff4747;
    width: 100px;
    height: 3px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    -webkit-transform: translateX(50%);
    -moz-transform: translateX(50%);
    -ms-transform: translateX(50%);
    -o-transform: translateX(50%);
    transform: translateX(50%);
}

.bannner_section .section-title::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 40%;
    background-color: #ff4747;
    width: 100px;
    height: 3px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}


@media(max-width: 416px) {
    .bannner_section .section-title::before {
        right: 25%;
        width: 50px;
    }

    .bannner_section .section-title::after {
        left: 25%;
        width: 50px;

    }
}

@media(min-width: 416px) and (max-width: 576px) {
    .bannner_section .section-title::before {
        right: 29%;
        width: 65px;
    }

    .bannner_section .section-title::after {
        left: 29%;
        width: 65px;

    }


}

@media(min-width: 576px) and (max-width: 768px) {
    .bannner_section .section-title::before {
        right: 30%;
        width: 70px;
    }

    .bannner_section .section-title::after {
        left: 30%;
        width: 70px;

    }


}

@media (min-width: 768px) and (max-width: 1100px) {
    .bannner_section .section-title::before {
        right: 37%;
        width: 70px;
    }

    .bannner_section .section-title::after {
        left: 37%;
        width: 70px;

    }


}