
.view_admin .profile-user-img {
    border: 3px solid #39a0c5 !important;
    width: 150px!important;
    height: 150px!important;
    object-fit: cover;
  
}

.view_admin .social_info button i {
    color: white;
}

.view_admin .custom_bg {
    background: #e1e1e1;
    /*linear-gradient(180deg,#6a82fb,#fc5c7d) */
    padding: 20px 10px;
    border-radius: 5px;
}