/* select item search */
.select2-container .select2-selection--single {
    height: 45px !important;
    overflow: hidden;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 35px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 7px;
}
.select2-search--dropdown {
    display: none !important;
    
}
.select2-container--open .select2-dropdown--below {
    width: 180px !important;
}
.select2-container--default .select2-selection--single {
    border: 2px solid #ff4747 !important;
    border-right: none !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}