/* Mobile Menu */
.mobile-menu {
    display: none;
}

.mobile_side_drawer {
    display: none;
}


@media (max-width: 768px) {
    .mobile-menu .navbar-brand .mobile-icon {
        display: block;
    }

    .navbar-brand {
        margin-right: -15px !important;
        padding-top: 0px !important;
    }

    .mobile-menu {
        display: block;
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 50px;
        z-index: 1000;
        background-color: #EAE6E6;
        border-top: 1px solid #cac5c5;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;


    }

    .mobile-menu .axtra-menu .axtra-menu-item {
        list-style-type: none;
        display: inline-block;
        padding: 8px;
    }

    .mobile-menu .axtra-menu .axtra-menu-item p {
        color: black;
    }

    .mobile-menu .axtra-menu .axtra-menu-item a .extra-icon {
        font-size: 22px;
        color: #000405;
    }

    .mobile-menu .axtra-menu .axtra-menu-item a .mini-counter-item {
        position: relative;
        background: #ff4747;
        color: #fff;
        font-size: 11px;
        font-weight: 500;
        border-radius: 50%;
        height: 5px;
        width: 5px;
        padding: 2px 4px;
        top: 1px;
        margin-left: -3px;
    }

    /* .mobile-menu .axtra-menu .axtra-menu-item a .wishlist {
        top: 1px;
        margin-left: -2px;
    } */

    .mobile-menu .axtra-menu .axtra-menu-item p {
        font-size: 14px;
        margin-top: -4px;
        display: block !important;
    }



    /* is login dropdown menu  */
    .mobile-menu .axtra-menu .axtra_menu_loging_account .user_img {
        height: 30px;
        width: 32px;
        border: 2px solid #00c3ff;
        border-radius: 50%;
        object-fit: contain;
    }

    .mobile-menu .axtra-menu .axtra_menu_loging_account .login-text {
        display: block !important;
        margin-top: -25px;
        margin-left: 18px;
        font-size: 13px;
        font-weight: 500;
        color: rgb(36, 34, 34);
    }

    .mobile-menu .axtra-menu .axtra_menu_loging_account {
        position: relative;
        cursor: pointer;
    }

    .mobile-menu .axtra-menu .axtra_menu_loging_account .login_hover_menu {
        position: absolute;
        content: " ";
        width: 180px;
        right: -54px;
        bottom: 65px !important;
        font-size: 15px;
        opacity: 0;
        display: none;
        visibility: hidden;
        transition: all 5s ease;
    }

    .mobile-menu .axtra-menu .axtra_menu_loging_account:hover .login_hover_menu {
        opacity: 1;
        display: block;
        visibility: visible;
        transition: all 5s ease;
    }

    .mobile-menu .axtra-menu .axtra_menu_loging_account .login_hover_menu i {
        color: #ff4747;
    }

    .mobile-menu .axtra-menu .axtra_menu_loging_account .login_hover_menu a {
        color: #00c3ff;
    }

    .mobile-menu .axtra-menu .axtra_menu_loging_account .custom_hover:hover {
        background-color: #00c3ff;
    }

    .mobile-menu .axtra-menu .axtra_menu_loging_account .custom_hover:hover a {
        color: white;
    }



    .mobile_side_drawer {
        display: block;
    }

    .mobile_side_drawer .menu {
        max-height: 100%;
        position: fixed;
        overflow-y: scroll !important;
        overflow-x: hidden !important;
        width: 100%;
        height: 100vh;
        left: -100%;
        top: 0;
        background: #EAE6E6;
        z-index: 999;
        transition: all 0.1s ease;
    }

    .mobile_side_drawer .menu.active {
        transition: all 0.1s ease;
        left: 0;
    }

    .mobile_side_drawer .menu .hide-btn {
        background: #ff4747;
        color: white;
        padding: 0px 8px;
        border: none;
        border-radius: 50%;
        font-size: 19px;
        float: right;
        margin-top: 10px;
        margin-right: 20px;
    }

    .mobile_side_drawer .mobile_left_category {
        margin-top: 10px;
    }

    .mobile_side_drawer .mobile-menu-item {
        margin-top: 10px;
        padding: 2px 5px;
        width: 60%;
        font-size: 14px;
        position: relative;
        margin-left: 30px;
        color: rgb(0, 0, 0);
        list-style-type: none;
        font-weight: 400;

    }

    .mobile_side_drawer .sub-category-mobile {
        background-color: rgb(252, 252, 252);
        border-radius: 7px;
        border: 1px solid #cecece;
        width: 350px;
        left: 20%;
        margin-left: 20px;
        display: none;
        top: 2px;



    }

    .mobile_side_drawer .mobile-menu-item a {
        color: rgb(0, 0, 0);
    }

    .mobile_side_drawer .mobile-menu-item:hover .sub-category-mobile {
        display: block;
    }

    .mobile_side_drawer .mobile-menu-item .mobile-sub-title {
        font-size: 16px;
        font-weight: 500;
        padding-top: 15px !important;
        color: black;
    }

    .mobile_side_drawer .mobile-menu-item .mobile-sub-menu-item {
        margin-left: 20px;
        list-style-type: none;
    }

    .mobile_side_drawer .mobile-menu-item .mobile-sub-menu-item a {
        color: black;
        font-size: 14px;
        font-weight: 400;
    }

    .mobile_side_drawer .mobile-menu-item .mobile-sub-menu-item a:hover {
        color: #ff4747;
        font-weight: 500;
        padding-left: 10px;
        transition: all 0.1s ease;
    }
}

@media (max-width: 540px) {
    .mobile-menu .axtra-menu .axtra_menu_loging_account .login_hover_menu {
        right: -25px;
        bottom: 65px !important;
    }
}