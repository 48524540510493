.shop_by_department_section{
    background: #f0f0f0;
    padding: 10px 0px;
    z-index: 900 !important;
    height: 38px;
}
.shop_by_department_section .custom-z-index{
    z-index: 900 !important;
}
.shop_by_department_section .title{
font-size: 16px;
font-weight: 400;
color: black;
padding: 2px;
cursor: pointer;
text-emphasis: none;
}
.shop_by_department_section .custom_style{
    width: 100%;
    margin-left: -13px !important;
}
.shop_by_department_section .shop_by_department_menu {
    width: 281px !important;
    border-radius: 6px;
    z-index: 999 !important;
    height: auto;
    display: block;
    padding-left: 0px;
    margin-top: 3px;
    padding-right: -5px;
    margin-left: -14px;
}
.shop_by_department_section .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition: height .35s ease;
         -o-transition: height .35s ease;
            transition: height .35s ease;
  }
.shop_by_department_section .menu-item {
    list-style: none;
    padding: 4px 20px;
    margin-left: 10px;
    position: relative;
}

.shop_by_department_section .menu-item a{
    color: rgb(0, 0, 0);
    font-size: 15px;
    padding-left: 20px;
    font-weight: 400;
    padding: 5px 5px;
}
.shop_by_department_section .menu-item a:hover{
    color: #ff4747;
    font-weight: 600;
    transition: all 0.2s ease;
}
.shop_by_department_section .menu-item:hover .sub-catagory{ 
    left: 100%;
    visibility: visible;
    opacity: 1;
    display: block;
    overflow: hidden;
    transition: all 2s ease;
}

.shop_by_department_section .sub-catagory{
    position:absolute;
    left: 0;
    padding: 5px 5px;
    top: -1px;
    bottom: 0;
    z-index:995;
    min-width: 910px !important;
    max-width: 1000px !important;
    top: 0;
    visibility: hidden;
    opacity: 0;   
    display: none;
    background-color:#fff;
    height: fit-content !important;
    border-radius: 6px;;
    z-index: 999;
}
.shop_by_department_section .sub-catagory .sub-title{
    font-size: 16px;
    font-weight: 500;
    color: black;
}
.shop_by_department_section .sub-catagory .sub-menu-item{
    list-style: none;
}
.shop_by_department_section .sub-catagory .sub-menu-item a{
    color: black;
    font-size: 14px;

}
.shop_by_department_section .sub-catagory .sub-menu-item a:hover{
    color: #ff4747;
    font-weight: 600;
    padding-left: 10px;
    transition: all 0.1s ease;
}
@media (max-width: 768px){
    .shop_by_department_section{
        display: none;
    }
}
@media (min-width: 768px) and (max-width: 991.98px) { 

    .shop_by_department_section{
        display: none;
    }

}
@media(max-width: 991px) {

    .medium_devices_allcategories .menu-item {
        list-style: none;
        padding: 5px 5px;
        position: relative;
        
    }
    .medium_devices_allcategories .menu-item a{
        color: rgb(0, 0, 0);
        font-size: 15px;
        font-weight: 400;
        padding: 5px 5px;
    }
    .medium_devices_allcategories .menu-item a:hover{
        color: #ff4747;
        font-weight: 600;
        transition: all 0.2s ease;
    }
 
    .medium_devices_allcategories .menu-item:hover .sub-catagory-md{
       display: block;
       opacity: 1;
       visibility: visible;
       transition: 0.5s ease;
    }
    .medium_devices_allcategories .shop_by_department_menu {
        background: #fff;

    }
    .medium_devices_allcategories .sub-catagory-md{
        position:absolute;
        width: 100%;
        padding: 5px 5px;
        top: 0px;
        left: 20%;
        bottom: 0;
        top: 2px;
        visibility: hidden;
        opacity: 0;   
        display: none;
        background-color:#fff;
        height: fit-content !important;
        border-radius: 6px;
        z-index: 700;
    }
    .medium_devices_allcategories .sub-catagory-md .sub-title{
        font-size: 16px;
        font-weight: 500;
        color: black;
    }
    .medium_devices_allcategories .sub-catagory-md .sub-menu-item{
        list-style: none;
    }
    .medium_devices_allcategories .sub-catagory-md .sub-menu-item a{
        color: black;
        font-size: 14px;
    
    }
    .medium_devices_allcategories .sub-catagory-md .sub-menu-item a:hover{
        color: #ff4747;
        font-weight: 600;
        padding-left: 10px;
        transition: all 0.1s ease;
    }
 }