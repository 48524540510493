.blog_right_site .custom_bg{
    background: #00c3ff;
    padding: 6px 10px;
    border-radius: 5px;
}

.blog_right_site  .custom_bg  span{
   font-size: 16px;
   color: white; 
   font-weight: 500;
}
.blog_right_site  .recent_post .thumbile {
    float: left;
}
.blog_right_site .recent_post .thumbile img{
    height: 50px;
}

.blog_right_site  .post_cate_info .post_cate_gory_meu .post_cate_gory_item{
    list-style-type: none;
    font-size: 16px;
    color: rgb(0, 0, 0);
    padding: 5px 5px;
}
.blog_right_site .post_cate_info .post_sub_category_menu .post_sub_cate_gory_item{
    list-style-type: none;
    font-size: 14px;
    padding: 1px 2px;
   color: rgb(78, 76, 76);
}