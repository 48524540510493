.banar_slider_section {
    overflow: hidden;
}

.banar_slider_section .position {
    position: static !important;
}


.banar_slider_section .slick-dots {
    bottom: 4px !important;
}

.banar_slider_section .slick-dots li button::before {
    color: white !important;
    font-size: 8px !important;
}

.banar_slider_section .slick-prev {
    left: 5px !important;
    opacity: 0.8;
}

.banar_slider_section .slick-next {
    right: 5px !important;
    opacity: 0.8;
}

.banar_slider_section .slick-prev,
.banar_slider_section .slick-next {
    z-index: 470 !important;
}

.banar_slider_section .slick-prev::before,
.banar_slider_section .slick-next::before {
    font-size: 16px !important;
}


.banar_slider_section .home_banner_right .banner_right_img {
    height: 190px;
    width: 100%;
    margin-left: 12px;
}



