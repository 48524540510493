.product_page .card_responsive{
  width:75%;
}

@media (max-width: 768px) { 
  .product_page .card_responsive{
    width:100%;
  }
}

.product_page .product-details .pro_name h2 {
  font-size: 23px;
  font-weight: 400;
  color: black;
}

.product_page .font-weight-blod {
  font-weight: 500;
  color: black;
}

.product_page .quantity i{
  cursor: pointer;
}
.product_page .quantity .not_allowed {
  cursor: not-allowed! important;
  opacity: 0.5;
}
.product_page .products-description .fw-bold {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1x;
}

.over-reviwes span {
  font-size: 14px;
  margin-right: 10px;
  font-weight: 400;
}

.over-reviwes .ratting span i {
  color: #ff4747;
  margin-right: 2px;
  cursor: pointer;
}

.over-reviwes .ratting {
  position: relative;
}

.over-reviwes .ratting:hover .hoverable_percentage {
  opacity: 1;
  visibility: visible;
  transform: translateY(8px);
  cursor: pointer;
  transition: all 0.3s ease;
}

.over-reviwes .hoverable_percentage {
  position: absolute;
  width: 240px;
  min-width: 200px;
  padding: 2px 10px;
  height: auto;
  top: 28px;
  left: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 900;
  transition: all 0.1s ease;
  background-color: #fff;
  border-radius: 10px;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #ff4747 !important;
  border-color: #ff4747 !important;
}
.products-description .list-group-item {
  padding: 4px 5px !important;
}

.by-same-product .product-card {
  width: 90%;
  height: 285px;
}

.by-same-product .product-card .img-fit {
  height: 200px;
}
