/* user profile card form section css */
.user_profile_card {
    position: relative;
    padding: 50px;
}

.user_profile_card .user_img {
    position: absolute;
    content: " ";
    top: 32px;
    left: 50%;
    transform: translateX(-50%);
    height: 100px;
    width: 100px;
    object-fit:contain;
    z-index: 900;
    border: 5px solid #00c3ff;
    border-radius: 50%;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .3);
    transition: all .2s ease-in-out 0s;
}

.user_details {
    margin-top: 40px;
}


.user_nav li {
    list-style-type: none;
    padding: 3px;
}


.user_nav li a {
    font-size: 17px;
}


/* form */

.user_profile_card_form_section .user_profile_form span {
    font-size: 14px;
}

.user_profile_card_form_section .user_profile_form .input-group span i {
    color: #00c3ff;
}

.user_profile_card_form_section .user_profile_form label i {
    color: #00c3ff;
}

.user_profile_card_form_section .user_profile_form .input-group .css-b62m3t-container {
    width: 90% !important;
}

/* change password section css */
.user_changepassword_section .user_change_password .custom_font {
    font-size: 18px;
    color: rgb(20, 20, 20);
}

.user_changepassword_section .user_change_password label .custom_font {
    font-size: 11px;
}

.user_changepassword_section .user_change_password span {
    font-size: 13px;
}


/* order */
.all_orders .custom_bg{
    background-color: #00c3ff !important;
}


.order_multi_step .accordion .collapse.show{
    height: auto !important;
}

  .order_multi_step .accordion-button.collapsed::after {
    font-family:  "Font Awesome 5 Free" !important;
    content: "";
    width: 22px;
    height: 22px;
    display: inline-block;
    color: #fff;
    background-image: none !important;
    transform: rotate(0deg) !important; 
    
}

.order_multi_step .accordion-button:after {
    font-family:  "Font Awesome 5 Free" !important;
    content: "";
    width: 22px;
    height: 22px;
    display: inline-block;
    color: #fff;
    background-image: none !important;
    transform: rotate(0deg) !important; 
    }

.order_multi_step .accordion-button {
        padding: 10px 10px !important;
       color: white !important;
       background-color: #00c3ff !important;
    }
 .order_multi_step .accordion-button:not(.collapsed) {
    color: white !important;
    background-color: #00c3ff !important;
  }




/* order */


@media (max-width: 991.98px) {
    .user_profile_card_form_section .user_profile_form .input-group .css-b62m3t-container {
        width: 80% !important;
    }
    

}

@media (max-width: 450px) {
    .user_profile_card_form_section .user_profile_form .input-group .css-b62m3t-container {
        width: 75% !important;
    }
}