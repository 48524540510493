.chatbot_wrapper{
    position: fixed;
    bottom: 80px;
    right: 7px;
    z-index: 999;
}
.chatbot_wrapper .chatbot_menu li{
    list-style-type: none;
}
.chatbot_wrapper .chatbot_menu li a{
   font-size: 20px;
    border-radius: 50%;
    color: white;
    text-align: center;
    cursor: pointer;
    outline: none;
    box-shadow: 0 9px 25px 0 rgba(132, 128, 177, 0.28);
    overflow: hidden;
}
.chatbot_wrapper .chatbot_menu .triger_hover .comment_icon{
  font-size: 25px;
  background: #ff4747;
  padding: 5px 12px !important;
  display: block;
  width: 50px;
  height: 50px;
  text-align: center;
}
.chatbot_wrapper .chatbot_menu .hoverle li .whatsapp_icon{
    padding: 8px 10px;
    background: #25D366;
}
.chatbot_wrapper .chatbot_menu .hoverle li .massenger_icon{
    padding: 8px 10px;
    background:  linear-gradient(180deg, #bb1b1b, #220fff);
}
.chatbot_wrapper .chatbot_menu .triger_hover a:hover i{
    transform: rotate(360deg);
    transition: 1s;
}
.chatbot_wrapper .chatbot_menu .triger_hover{
    position: relative;
}
.chatbot_wrapper .chatbot_menu .hoverle{
    position: absolute;
    right: 100px;
    top: -20px;
    content: "";
    height: 100%;
    width: 100%;
    z-index:999;
    display: none;
    opacity: 0;
}

.chatbot_wrapper .chatbot_menu .triger_hover:hover .hoverle {
    top: -50px;
    display: block;
   opacity: 1;
   transition: all 1s ease;
}
.animated {
    animation: bounce 1s infinite alternate;
    -webkit-animation: bounce 1s infinite alternate;
  }
  @keyframes bounce {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(-15px);
    }
  }
  @-webkit-keyframes bounce {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(-15px);
    }
  }
  .animated:hover{
      animation: none;
  }
  
  @media (max-width: 766px) { 
    .chatbot_wrapper{
      bottom: 110px;
  }
  .chatbot_wrapper .chatbot_menu .triger_hover .comment_icon{
    padding: 5px 12px !important;
    display: block;
    width: 50px;
    height: 50px;
    text-align: center;
  }
  .chatbot_wrapper .chatbot_menu .hoverle li .whatsapp_icon{
    padding: 6px 8px;
}
.chatbot_wrapper .chatbot_menu .hoverle li .massenger_icon{
    padding: 6px 8px;
}
.chatbot_wrapper .chatbot_menu .hoverle{
  right: 95px;
  top: -20px;
}
.chatbot_wrapper .chatbot_menu .triger_hover:hover .hoverle {
  top: -44px;
}
   }
