 .privacy_policy_section .title{
    position: relative;
    font-weight: 500;
}
.privacy_policy_section .title::before{
    content: "";
    position: absolute;
    top: 50%;
    right: 40%;
    background-color: #00c3ff;
    width: 90px;
    height: 3px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    -webkit-transform: translateX(50%);
    -moz-transform: translateX(50%);
    -ms-transform: translateX(50%);
    -o-transform: translateX(50%);
    transform: translateX(50%);
}

.privacy_policy_section .title::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 40%;
    background-color: #00c3ff;
    width: 90px;
    height: 3px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}

@media (max-width: 576px) { 
   .privacy_policy_section .title::before{
        right: 28%;
        width: 50px;
    }
    
   .privacy_policy_section .title::after {
        left: 28%;
        width: 50px;
    
    }
    
    
     }
@media(min-width: 576px) and (max-width: 768px) { 
   .privacy_policy_section .title::before{
        right: 30%;
        width: 60px;
    }
    
   .privacy_policy_section .title::after {
        left: 30%;
        width: 60px;
    
    }
    
    
     }
@media (min-width: 768px) and (max-width: 991.98px) {
.privacy_policy_section .title::before{
    right: 33%;
    width: 80px;
}

.privacy_policy_section .title::after {
    left: 33%;
    width: 80px;

}


 }