.comming_soon_sec .contact-banner{
    opacity: 0.4;
    width: 100%;
    height: 500px;
}

.comming_soon_sec .banner_wrapper{
    position: relative;
}
.comming_soon_sec .custom-banner-text{
    position: absolute;
    top: 0;
    content: "";
    left: 0;
    content: "";
    height: 100%;
    width: 100%;
    background: #6ecacc;
    opacity: 0.8;
    margin: auto;
}
.comming_soon_sec .custom-banner-text h2{ 
    color: black;
    font-size: 25px;
    font-weight: 600;
    padding-top: 200px;
}