.main-sidebar.custom_bg{
    background-color:#1a2b3c !important;
}
.main-sidebar .nav-icon{
    color: #8ed6f0 !important;
}

.main-sidebar .custom_site_icon{
    height: 50px !important;
}
.main-sidebar .user-panel .user_img{
    height: 47px;
    width: 47px;
    object-fit: cover;
    border: 3px solid #39a0c5;
    box-shadow: 0 1px 4px rgba(0,0,0,.3);
}
.main-sidebar .nav-sidebar > .nav-item {
    font-size: 14px !important;
    line-height: 9px !important;
  }


.main-sidebar .nav-pills .nav-link {
    border-radius: 0.25rem !important;
    font-size: 13px !important;
    text-transform: capitalize !important;
    background-color: initial;
    font-weight: 400;
    color: none !important;
    padding: .5rem 8px;
}