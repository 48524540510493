.traditional_section .custom_bg {
    background: #F8FBFD;
}

.traditional_section .custom_img {
    height: 120px;
    margin: auto;
    display: block;
}

.traditional_section .hoverle_card:hover {
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 8px 0 rgba(0, 0, 0, 0.19);
    border: 1px solid rgb(243, 230, 230) !important;
    transition: all 0.5s ease;
}