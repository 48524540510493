#banner_product_carousel .section-img{
    width: 40px !important;
    height: 40px !important;
}

#banner_product_carousel .title{
    font-size: 18px;
    color: black;
    font-weight: 500;
}
#banner_product_carousel .section-title{
    position: relative;
}
#banner_product_carousel .section-title::before{
    content: "";
    position: absolute;
    top: 50%;
    right: 35%;
    background-color: #39a0c5;
    width: 120px;
    height: 3px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

#banner_product_carousel .section-title::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 35%;
    background-color: #39a0c5;
    width: 120px;
    height: 3px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}
 /* #banner_product_carousel  .custom-box-shadow{
    background-color: rgb(240, 240, 240);
    border-radius: 10px;
} */
/* #banner_product_carousel  .custom-box-shadow:hover{
    box-shadow: 0px 1px 10px 0px rgba(0,0,0,0.2),0 1px 20px 0 rgba(0,0,0,0.19) !important;
    background-color: rgb(240, 240, 240);
}  */

#banner_product_carousel  .product-card{
    height: auto !important;
}