.shop-section .related-category h5 {
  font-size: 16px;
  color: rgb(34, 31, 31);
  font-weight: 500;
  margin-bottom: 2px !important;
}

.shop-section .related-category a span {
  font-size: 14px;
}

.shop-section .latest-product h5 {
  font-size: 16px;
  font-weight: 500;
  color: rgb(34, 31, 31);
  margin-bottom: 2px !important;
}

.shop-section .latest-product span {
  font-size: 14px;
}

.shop-section .sort-by .sort_select {
  height: 35px;
  width: 100%;
  margin-top: -5px;
  border: 1px solid #b8aeae;
  border-radius: 4px;
  background: #f0f0f0;
}

.shop-section .sort-by span {
  font-size: 14px;
  color: black;
  font-weight: 400;
  cursor: pointer;
}

.shop-section .sort-by .views i {
  font-size: 20px;
}

.shop-section .sort-by .views .opacity {
  opacity: 1;
}

.shop-section .sort-by .views .setopacity {
  color: #ff4747;
  opacity: 0.4;
}

.shop-section .left-side label {
  font-size: 14px;
  color: rgb(83, 82, 82);
}

.shop-section .left-side h5 {
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 3px !important;
  color: rgb(0, 0, 0);
}

.shop-section .left-side span a {
  font-size: 14px;
  color: #00c3ff;
  font-weight: 500;
}

.shop-section .left-side .product-ratting .rating {
  color: #ff4747;
  font-size: 14px;
}

.shop-section .left-side .product-ratting .unrating {
  color: #a59e9e;
  font-size: 14px;
  opacity: 0.7;
}

.shop-section .list-group-item {
  padding: 2px 4px !important;
}

.shop-section .filter_by_clear {
  font-size: 12px;
  cursor: pointer;
}

.shop-section .form-range::-moz-range-thumb {
  background-color: #00c3ff !important;
}

.shop-section .range .thumb::after {

  background-color: #00c3ff !important;

}

.shop-section .mobile_filter_hide {
  display: none;
}

.shop-section .mobile_filter_btn {
  display: none;
}





.shop-section .grid_img {
  height: 260px;
  width:100%;
  margin: auto;
  display: block;
}

.shop-section .grid_cart {
  cursor: pointer;
}



@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,900&display=swap');

.shop-section .grid_product_details .name {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  font-family: 'Raleway', sans-serif;
}

.shop-section .grid_product_details .name:hover {
  text-decoration: underline;
  color: #ff4747;
  transition: 0.2s all;
}

.shop-section .grid_product_details .prices {
  font-family: 'Raleway', sans-serif;
  margin-bottom: 3px;
}

.shop-section .grid_product_details .brand,
.rating_wrap,
.color_wrap {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 3px;
  line-height: normal;
  font-family: 'Raleway', sans-serif;
}

.shop-section .grid_product_details .rating_wrap .rating {
  color: #ff4747;
  font-size: 14px;
  margin-bottom: 3px;
}

.shop-section .grid_product_details .rating_wrap .unrating {
  color: #a59e9e;
  font-size: 14px;
  opacity: 0.7;
}

.shop-section .grid_product_details a:hover {
  text-decoration: underline;
}

.shop-section .grid_product_details ul li {
  list-style-type: disc;
  color: #6b6b6b;
  padding: 2px 0px;
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  font-family: 'Raleway', sans-serif;
}













@media (max-width: 767.98px) {
  .shop-section .mobile_filter_hide {
    display: block;
  }

  .shop-section .latest-product h5 {
    font-size: 14px;
    font-weight: 400;
    color: black;
    text-align: center;
    justify-content: center !important;
    margin-bottom: 2px !important;
  }

  .shop-section .sm-d-block {
    justify-content: center !important;
    display: block;
  }

  .shop-section .sort-by {
    justify-content: center !important;
  }

  /* mobile filtering style */
  .shop-section .mobile_filter_btn {
    display: block;
  }

  .shop-section .mobile_filter_btn .custom_bg {
    background-color: rgb(233, 231, 231);
    cursor: pointer;
  }

  .shop-section .left-side {
    position: fixed;
    top: 75px;
    z-index: 800;
    background: #f1f1f1;
    width: 50%;
    overflow-y: scroll !important;
    height: 100vh;
    padding-bottom: 145px;

  }

  .shop-section .custom_mobile_hide {
    display: none;
  }

  .shop-section .custom_mobile_show {
    display: block;
    transition: 0.5s all;
  }
}