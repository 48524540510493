.product-card {
    width: 100%;
    height: 305px;
    overflow: hidden;
    margin: auto;
    display: block;
}

.product-card .img-fit {
    max-height: 100%;
    width: 100%;
    height: 215px;
    object-fit: cover;
    opacity: 1;
}

.product-card:hover .img-fit {
    transform: scale(1.05);
    opacity: 0.9;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.product-card .z_index{
    z-index: 500;
}

.product-card .hover_icon .icon_wrap {
    display: block;
    height: 32px;
    width: 32px;
    cursor: pointer;
    line-height: 32px;
    border-radius: 50%;
    text-align: center;
    background: #fff;
    margin-top: 5px;
    color: #333;
    font-size: 15px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 10%), 0 3px 8px rgb(0 0 0 / 12%);
    transform: translateX(calc(100% + 9px));
    -webkit-transform: translateX(calc(100% + 9px));
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;

}
.product-card:hover .hover_icon .icon_wrap {
    transform: translateX(0);
    -webkit-transform: translateX(0);
}





.product-card .hover_icon .icon_wrap:hover {
    background: #00c3ff;
    color: #fff;
}

.product-card .hover_icon i {
    font-size: 13px;
}


.product-card .product_name {
    height: 35px;
    line-height: 1.4 !important;
    color: black;
    font-size: 0.8125rem !important;
    font-weight: 600 !important;
}


@media (min-width: 550px) and (max-width: 768px) {
    .product-card {
        height: 285px;

    }

    .product-card img {
        height: 220px;
    }
}
