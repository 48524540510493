
.shop_by_depertment .shop_by_department_section .collapse:not(.show) {
    display: block !important;
}
.shop_by_depertment .shop_by_department_section .collapsing {
    display: block !important;
    height: fit-content !important;
  }
  .shop_by_depertment .shop_by_department_section .collapse{
    display: block !;
}