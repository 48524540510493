.workshop_seminar_section .container {
    max-width: 1100px !important;
}
.workshop_seminar_section .custom_bg {
    background-color: #f9fafd;
    height: auto;
    border-radius: 2px;
    /* #f2f7f6; */
}
.workshop_seminar_section .workshop_card:hover{
    transform: translateY(-8px);
    transition: all 0.5s;
}
.workshop_seminar_section .workshop_card p i{
    color: #a27a7a;
}

