.footer-body {
    background:  #102d37 ;
    padding-top: 20px;
    overflow: hidden;
}

.footer-section .footer-title {
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.1px;
    color: white;
    border-bottom: 1px solid #ff4747;
    padding-bottom: 8px;
}
.footer-section ul{
    padding:  0px 0px !important;
    margin-bottom: 5px !important;
}
.footer-section ul li {
    list-style-type: none;
   padding-top: 2px;
}
.footer-section ul li a{
    font-size: 12px;
}
.footer-section ul li .hover_effect{
    color: rgb(209, 209, 209);
    position: relative;
}
.footer-section ul li .hover_effect:hover{
    color: #ff4747;
    margin-left: 10px;
    transition: 0.9s ease;
}
.footer-section ul li .hover_effect::before{
    position: absolute;
    content:"";
    height: 1px;
    width:0%;
    background: #ff4747;
    top: 16px;
    bottom: 0;
    transition: all 0.3s ease;
  }
  .footer-section ul li .hover_effect:hover::before{
      width: 100%;
  }
  

.footer-section .stay_connect{
    font-size: 12px;
    color: white;
}

.footer-section .social-media {
    padding-bottom: 4px;
}
.footer-section .social-media ul li{ 
    list-style-type: none;
    display: inline-block;
    margin-right: 10px;
 
}
.footer-section .social-media a {
    display: inline-block;
    text-decoration: none;
    border-radius: 50%;
    height: 28px;
    width: 30px;
    color: white;
    text-align: center;
}
.footer-section  .social-media a i{
    font-size: 12px;
    font-weight: 200;
    margin-top: 8px;
}
.footer-section   .social-media .facebook {
    background: #3B5998;
}
.footer-section   .social-media .twitter {
    background: #28aae1;
}
.footer-section   .social-media .linkedin {
    background: #0077ff;
}
.footer-section   .social-media .instagram {
    background: #CF2158;
}
.footer-section   .social-media .pinterest {
    background: #C41F26;
}
.footer-section   .social-media .youtube {
    background: #e92b2b;
}
.footer-section  .social-media a:hover i{
    transform: rotate(360deg);
    transition: all 0.3s ease;
}
.footer-section   p {
    color: #f7f7f7;
    font-size: 12px;
    padding: 4px 0px;
    font-weight: 300;
    margin-bottom: 0px !important;
}



