.image_store_Section .prices_cart {
    position: absolute;
    content: " ";
    background-color: rgba(0, 0, 0, 0.39);
    height: 30px;
    width: 100%;
    bottom: 0;
    z-index: 500;
}

.image_store_Section .prices_cart .hover_icon i {
    color: white;
    font-size: 20px;
}

.image_store_Section .prices_cart .hover_icon .cart {
    font-size: 17px;
}

.image_store_Section .prices_cart .hover_icon i:hover {
    color: #ff3547 !important;
}

.image_store_Section .section-title {
    font-size: 18px;
    color: black;
    position: relative;
    font-weight: 500;
}

.image_store_Section .section-title::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 40%;
    background-color: #00c3ff;
    width: 100px;
    height: 3px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    -webkit-transform: translateX(50%);
    -moz-transform: translateX(50%);
    -ms-transform: translateX(50%);
    -o-transform: translateX(50%);
    transform: translateX(50%);
}

.image_store_Section .section-title::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 40%;
    background-color: #00c3ff;
    width: 100px;
    height: 3px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}


@media(max-width: 416px) {
    .image_store_Section .section-title::before {
        right: 25%;
        width: 50px;
    }

    .image_store_Section .section-title::after {
        left: 25%;
        width: 50px;

    }
}

@media(min-width: 416px) and (max-width: 576px) {
    .image_store_Section .section-title::before {
        right: 29%;
        width: 65px;
    }

    .image_store_Section .section-title::after {
        left: 29%;
        width: 65px;

    }


}

@media(min-width: 576px) and (max-width: 768px) {
    .image_store_Section .section-title::before {
        right: 30%;
        width: 70px;
    }

    .image_store_Section .section-title::after {
        left: 30%;
        width: 70px;

    }


}

@media (min-width: 768px) and (max-width: 1100px) {
    .image_store_Section .section-title::before {
        right: 37%;
        width: 70px;
    }

    .image_store_Section .section-title::after {
        left: 37%;
        width: 70px;

    }


}