.product_add .form-group{
    margin-bottom: 7px !important;
}

.edit_product .product-image-thumb button{
  position:absolute;
  content: '';
  bottom: -40px;
  margin: 5px auto;
  font-size: 11px;
}