.new_arrivals .img_fit{
    max-height: 100%;
    width: 130px;
    object-fit: cover;
    border-radius: 2px;
}

.new_arrivals .custom-box-shadow:hover {
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .21);
}



.new_arrivals .section-title {
    font-size: 18px;
    color: black;
    position: relative;
    font-weight: 500;
}

.new_arrivals .section-title::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 40%;
    background-color: #00c3ff;
    width: 100px;
    height: 3px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    -webkit-transform: translateX(50%);
    -moz-transform: translateX(50%);
    -ms-transform: translateX(50%);
    -o-transform: translateX(50%);
    transform: translateX(50%);
}

.new_arrivals .section-title::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 40%;
    background-color: #00c3ff;
    width: 100px;
    height: 3px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}


@media(max-width: 416px) {
    .new_arrivals .section-title::before {
        right: 25%;
        width: 50px;
    }

    .new_arrivals .section-title::after {
        left: 25%;
        width: 50px;

    }
}

@media(min-width: 416px) and (max-width: 576px) {
    .new_arrivals .section-title::before {
        right: 29%;
        width: 65px;
    }

    .new_arrivals .section-title::after {
        left: 29%;
        width: 65px;

    }


}

@media(min-width: 576px) and (max-width: 768px) {
    .new_arrivals .section-title::before {
        right: 30%;
        width: 70px;
    }

    .new_arrivals .section-title::after {
        left: 30%;
        width: 70px;

    }


}

@media (min-width: 768px) and (max-width: 1100px) {
    .new_arrivals .section-title::before {
        right: 37%;
        width: 70px;
    }

    .new_arrivals .section-title::after {
        left: 37%;
        width: 70px;

    }


}