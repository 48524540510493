.page_banner .contact-banner{
    border-radius: 15px;
    opacity: 0.6;
    width: 100%;
    height: 140px;
}

.page_banner .banner_wrapper{
    position: relative;
}
.page_banner .custom-banner-text{
    position: absolute;
    top: 0;
    content: "";
    left: 0;
    content: "";
    height: 100%;
    width: 100%;
    background: #423737;
    opacity: 0.8;
    margin: auto;
    border-radius: 15px;
}
.page_banner .custom-banner-text p{ 
    color: white;
    font-size: 25px;
    font-weight: 600;
    padding-top: 25px;
}