.contact_us_sec {
    overflow: hidden;
}
/* Form select style */
.contact_us_sec  .contact_select {
    word-wrap: normal;
    width: 100%;
    height: 35px;
    background: white;
    color: #4f4f4f;
    border: 1px solid #bdbdbd;
    border-radius: 4px;
}
.contact_us_sec .title{
    font-size: 18px;
    color: black;
    position: relative;
    font-weight: 500;
}
.contact_us_sec .title::before{
    content: "";
    position: absolute;
    top: 50%;
    right: 38%;
    background-color: #39a0c5;
    width: 100px;
    height: 3px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    -webkit-transform: translateX(50%);
    -moz-transform: translateX(50%);
    -ms-transform: translateX(50%);
    -o-transform: translateX(50%);
    transform: translateX(50%);
}

.contact_us_sec .title::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 40%;
    background-color: #39a0c5;
    width: 100px;
    height: 3px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}


@media(max-width: 416px){
    .contact_us_sec .title::before{
        right: 23%;
        width: 50px;
    }
    
    .contact_us_sec .title::after {
        left: 23%;
        width: 50px;
    
    }
}
@media(min-width: 416px) and (max-width: 576px) { 
    .contact_us_sec .title::before{
        right: 29%;
        width: 65px;
    }
    
    .contact_us_sec .title::after {
        left: 29%;
        width: 65px;
    
    }
    
    
     }
@media(min-width: 576px) and (max-width: 768px) { 
    .contact_us_sec .title::before{
        right: 30%;
        width: 70px;
    }
    
    .contact_us_sec .title::after {
        left: 30%;
        width: 70px;
    
    }
    
    
     }
@media (min-width: 768px) and (max-width: 1100px) {
.contact_us_sec .title::before{
    right: 37%;
    width: 70px;
}

.contact_us_sec .title::after {
    left: 37%;
    width: 70px;

}


 }