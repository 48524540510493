.wishlist_section .wishlist_product_details .wishlist_img {
    height: 60px;
    width: 60px;
    vertical-align: middle;
    object-fit: cover;
}

.wishlist_section .modal-open {
    overflow: scroll !important;
    padding-right: 0px !important;
}


.wishlist_section .mobile_cart .card-body {
    padding: 10px 5px 5px 5px !important;
}

.wishlist_section .mobile_cart .mobile_wishlist_img {
    height: 100px;
    width: 100px;
    display: block;
    margin:  5px auto;
    object-fit: cover;
}

/* title */
.wishlist_section .section-title {
    font-size: 22px;
    color: black;
    position: relative;
    font-weight: 300;
}

.wishlist_section .section-title::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 40%;
    background-color: #39a0c5;
    width: 100px;
    height: 3px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    -webkit-transform: translateX(50%);
    -moz-transform: translateX(50%);
    -ms-transform: translateX(50%);
    -o-transform: translateX(50%);
    transform: translateX(50%);
}

.wishlist_section .section-title::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 40%;
    background-color: #39a0c5;
    width: 100px;
    height: 3px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}


@media(max-width: 416px) {
    .wishlist_section .section-title::before {
        right: 25%;
        width: 50px;
    }

    .wishlist_section .section-title::after {
        left: 25%;
        width: 50px;

    }
}

@media(min-width: 416px) and (max-width: 576px) {
    .wishlist_section .section-title::before {
        right: 29%;
        width: 65px;
    }

    .wishlist_section .section-title::after {
        left: 29%;
        width: 65px;

    }


}

@media(min-width: 576px) and (max-width: 768px) {
    .wishlist_section .section-title::before {
        right: 30%;
        width: 70px;
    }

    .wishlist_section .section-title::after {
        left: 30%;
        width: 70px;

    }


}

@media (min-width: 768px) and (max-width: 1100px) {
    .wishlist_section .section-title::before {
        right: 37%;
        width: 70px;
    }

    .wishlist_section .section-title::after {
        left: 37%;
        width: 70px;

    }


}