.community_tourism_section{
    overflow: hidden;
}
.community_tourism_section .hoverble_img{
    height: 50px;
    width: 50px;
    transition: all 1s ease;
}
.community_tourism_section  .custom_bg{
    /* background-color:#eee5e5 ; */
    /* background-image: linear-gradient(to right,#f4f4f4,#b2d2ad); */
    /* background-image: linear-gradient(to right,#d2c8b4,#c5d5c0); */
    background-image: linear-gradient(to right,#f6fdf9,#d7ece6);
    /* background: radial-gradient(53.8% 74.52% at 69.44% 0%, rgb(200, 233, 255) 0%, rgba(255, 255, 255, 0) 100%); */
    padding: 15px 8px;
}
.community_tourism_section  .hoverle_card .custom_color{
    color: #39a0c5;
}
.community_tourism_section  .hoverle_card p{
    font-size: 14px;
    font-weight: 400;
} 

.community_tourism_section .hoverle_card:hover{
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 8px 0 rgba(0, 0, 0, 0.19);
    transition: all 0.5s ease;
    transform: translateY(-5px)
}

