.header {
    background-color: #EAE6E6;
    position: sticky;
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 0px;
    z-index: 991;
    height: 75px;
}

.header .navbar-brand .mobile-icon {
    display: none;
}

/* header category start css*/
.header .header_category_wrapper .header_wrap {
    position: absolute;
    width: 280px !important;
    background: #fff;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    height: auto;
    top: 48px;
    left: -350%;
    display: none;
    opacity: 0;
    visibility: hidden;
    z-index: 900;
    transition: all 2s ease;
}

.header_category_wrapper:hover .header_wrap {
    display: block;
    opacity: 1;
    visibility: visible;
    transition: all 2s ease;
}

.header .header_category_wrapper {
    position: relative;
    cursor: pointer;
    display: block;
    width: 50%;
}

.header .header_category_wrapper:hover {
    height: 120px;
}

.header .header_category_wrapper:hover .header-category-menu-btn {
    opacity: 0.5;
}

.header .header_category_wrapper .menu-item {
    list-style: none;
    padding: 4px 20px;
    padding-left: 10px;
    position: relative;
}

.header .header_category_wrapper .menu-item a {
    color: rgb(0, 0, 0);
    font-size: 15px;
    font-weight: 400;
    padding: 5px 5px;
}

.header .header_category_wrapper .menu-item:hover a {
    color: #ff4747;
    transition: all 0.5s ease;
}

.header .header_category_wrapper .menu-item:hover .sub-catagory {
    visibility: visible;
    opacity: 1;
    display: block;
    transition: all 2s ease;
}

.header .sub-catagory {
    position: absolute;
    left: 100%;
    padding: 5px 5px;
    border: 1px solid #fff;
    top: -1px;
    bottom: 0;
    z-index: 995;
    width: 813px !important;
    min-width: 200px;
    top: 0;
    visibility: hidden;
    opacity: 0;
    display: none;
    background-color: #fff;
    height: fit-content !important;
    border-radius: 6px;
    z-index: 900;
}

.header .header_category_wrapper .sub-catagory .sub-title {
    font-size: 16px;
    font-weight: 500;
    color: black;
}

.header .header_category_wrapper .sub-catagory .sub-menu-item {
    list-style: none;
}

.header .header_category_wrapper .sub-catagory .sub-menu-item a {
    color: black;
    font-size: 14px;

}

.header .header_category_wrapper .sub-catagory .sub-menu-item a:hover {
    color: #ff4747;
    font-weight: 600;
    padding-left: 10px;
    transition: all 0.1s ease;
}

@media (min-width: 768px) and (max-width: 991px) {
    .header .header_category_wrapper .header_wrap {
        width: 300px !important;
    }

    .header .header_category_wrapper .header_wrap {
        top: 36px;
        left: -950%;
    }
}

/* header category end */


/* search auto suggested style */
.header .search_suggested_list {
    background: #f5f3f3;
    width: 85%;
    z-index: 990;
    display: block;
    height: fit-content;
}

.header .search_suggested_list ul li {
    list-style-type: none;
    cursor: pointer;
}


.header .search_suggested_list .suggest_img {
    width: 50px;
    height: 30px;
}

.header .axtra-menu {
    padding-left: 0px !important;
}

.header .axtra-menu .axtra-menu-item {
    list-style-type: none;
    display: inline-block;
    padding-top: 8px;
}


.header .axtra-menu .axtra-menu-item .extra-icon {
    font-size: 22px;
    color: #00c3ff;;
    position: relative;
}

.header .axtra-menu .axtra-menu-item a .mini-counter-item {
    position: absolute;
    background: #ff4747;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    border-radius: 50%;
    padding: 4px;
    line-height: 10px;
    top: 30px;
    margin-left: -5px;
}

.header .axtra-menu .axtra-menu-account .login-text {
    display: block !important;
    margin-top: -25px;
    margin-left: 30px;
    font-size: 15px;
    font-weight: 500;
    color: rgb(36, 34, 34);
    text-align: center;
}

/* is wishlist add dropdown */
.header .dropdown-menu.animation {
    transform: translate(1050px, 95px) !important;
}

/* is login dropdown menu  */
.header .axtra-menu .axtra_menu_loging_account .user_img {
    height: 27px;
    width: 30px;
    object-fit: cover;
    border: 2px solid #00c3ff;;
    border-radius: 50%;
    margin-left: -12px;
}

.header .axtra-menu .axtra_menu_loging_account .login-text {
    display: block !important;
    margin-top: -25px;
    margin-left: 18px;
    font-size: 13px;
    font-weight: 500;
    color: rgb(36, 34, 34);
}

.header .axtra-menu .axtra_menu_loging_account {
    position: relative;
    cursor: pointer;
}

.header .axtra-menu .axtra_menu_loging_account .login_hover_menu {
    position: absolute;
    content: " ";
    width: 180px;
    right: -37px;
    top: 36px;
    font-size: 15px;
    opacity: 0;
    display: none;
    visibility: hidden;
    transition: all 5s ease;
}

.header .axtra-menu .axtra_menu_loging_account:hover .login_hover_menu {
    opacity: 1;
    display: block;
    visibility: visible;
    transition: all 5s ease;
}

.header .axtra-menu .axtra_menu_loging_account .login_hover_menu i {
    color: #ff4747;
}

.header .axtra-menu .axtra_menu_loging_account .login_hover_menu a {
    color: #00c3ff;;
}

.header .axtra-menu .axtra_menu_loging_account .custom_hover:hover {
    background-color: #00c3ff;;
}

.header .axtra-menu .axtra_menu_loging_account .custom_hover:hover a {
    color: white;
}





/* input search */
.header .custom_search_input{
    border-top: 2px solid #ff4747 !important;
    border-bottom: 2px solid #ff4747 !important;
    border-left: none !important;
    height: 45px !important;

}

.header .custom_search_input:focus {
    box-shadow: 0 0 0 .1rem rgba(0, 123, 255, .25) !important;
    border-top: 2px solid #ff4747 !important;
    border-bottom: 2px solid #ff4747 !important;
}

/* submit-btn */
.header .search-camera {
    padding: 5px 8px;
    border-top: 2px solid #ff4747;
    border-bottom: 2px solid #ff4747;



}

.header .search-submit {
    display: inline-block;
    padding: 5px 10px;
    overflow: hidden;
    color: white;
    font-size: 14px;
    border: none;
    outline: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

}


/* responsive */
@media (max-width: 768px) {
    .header .navbar-brand .desktop-logo {
        display: none;
    }

    .sm-hide {
        display: none;
    }

    .header .navbar-brand .mobile-icon {
        display: block;
    }

    .navbar-brand {
        margin-right: -15px !important;
        padding-top: 0px !important;
    }

}


@media (max-width: 991.98px) {

    .header .axtra-menu .axtra-menu-item {
        margin: 0px 10px;
    }

    .header .axtra-menu .axtra-menu-item.axtra_menu_loging_account {
        margin: 0px 5px;
    }

    .header .axtra-menu .axtra_menu_loging_account .login-text {
        font-size: 12px;
        margin-left: 20px;
    }

    .header .axtra-menu .axtra_menu_loging_account .login_hover_menu {
        right: -28px;
        top: 44px;
    }

    .header .axtra-menu .axtra-menu-item a .mini-counter-item {
        top: 33px;
        margin-left: -10px;
    }
}