
/* .custom_img{
    height: 200px;
} */

.blog_card .card-body{
    padding: 5px 2px !important;

}
.blog_card .card-title{
    font-size: 16px;
    font-weight: 500;
}
.blog_card .card-title a{
    color: rgb(77, 74, 74);
}
.blog_card .card-title a:hover{
    color: rgb(20, 20, 20);
}
.blog_card .post_info span{
    font-size: 13px;
    color: rgb(0, 0, 0);
}
.blog_card .post_info i{
    font-size: 12px;
}
