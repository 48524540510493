/* modal css */
.modal-header {
    padding: 7px 5px !important;
}

.modal-backdrop {
    z-index: 900 !important;
}

.modal-dialog .modal-content {
    border-radius: 15px !important;
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(123, 29, 29, 0.15);
    top: 88px;
    padding: 0px 30px;
    padding-bottom: 10px;

}

.modal-body {
    padding: 0px 10px !important;
}

.site-icon {
    width: 40px;
    height: 40px;
    display: block;
    margin: auto;
    margin-right: 10px;
}

#log_register_panel .nav-tabs .nav-link {
    padding: 10px 29px 10px 29px !important;
}

/* login-register-css */

#login_register_popup .login-text {
    display: inline;
}

#log_register_panel .nav {
    flex-wrap: nowrap !important;
}

#log_register_panel .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: #ff4747;
    border-color: #ff4747;
    border-radius: 1px;
}

#log_register_panel .login-submit-btn {
    width: 80%;
    height: 45px;
    display: block;
    margin: auto;
}

#log_register_panel p {
    font-size: 13px;
    color: black;
}

#log_register_panel label {
    font-size: 14px;
    color: black;
}

#log_register_panel label i {
    font-size: 14px;
}

#log_register_panel a {
    font-size: 14px;
}

#log_register_panel span {
    font-size: 14px;
}

@media (max-width: 768px) {
    #log_register_panel p {
        font-size: 12px;
    }

    #log_register_panel label {
        font-size: 13px;
    }

    #log_register_panel a {
        font-size: 13px;
    }

    #login_register_popup .modal-dialog .modal-content {
        top: 68px;
    }

    #log_register_panel .login-submit-btn {
        width: 80%;
        height: 35px;
        line-height: 10px;
    }

    .site-icon {
        width: 35px;
        height: 35px;
    }
}