.styles_scroll-to-top__2A70v{
    background-color: none !important;
    border-radius: 20px !important;
    background: none !important;
    right: 20px !important;
    bottom: 15px !important;
    overflow: hidden;
}

  @media (max-width: 766px) { 
    .styles_scroll-to-top__2A70v {
        bottom: 60px !important;
      }
   }