.area_search .was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  background: white !important;
}

.area_search .form-outline {
  width: 100% !important
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400&display=swap');

.area_search .custom_title {
  color: #3a3b3c;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 15px;
}

.area_search .form-outline .form-control {
  background-color: white !important;
}

.area_search .css-tlfecz-indicatorContainer {
  color: hsl(0, 2.1%, 36.9%) !important;
}

.area_search .custom_bg {}