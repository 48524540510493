.footer-top {
    background-color: #122931;
    color: #bfbebd;
    overflow: hidden;
}

.footer .footer-top p a{
    color: white;
    font-size: 15px;
    font-weight: 200;
    letter-spacing: 0.4px;
}
.footer .footer-top p i:hover {
    background: #c62828;
    transform: rotate(360deg);
}
.footer .footer-top p i {
    color: white;
    background: #d32f2f;
    margin-right: 6px;
    font-size: 23px;
    border: 2px solid white;
    padding: 10px;
    border-radius: 50%;
    transition: 1s;
}