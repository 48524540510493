            /* form */
.checkout_section .input-group  .form-outline{
    width: 85% !important;

}
@media (max-width: 535px) { 
    .checkout_section .input-group  .form-outline{
        width: 75% !important;
    
    }
 }


/* .checkout_section .input-group .css-b62m3t-container {
    width: 400px !important;
  } */
  .checkout_section .input-group label{
      padding-right: 10px;
  }

/* Medium devices (tablets, less than 992px) */
/* @media (max-width: 991.98px) { 

    .checkout_section .input-group .css-b62m3t-container {
        width: 80% !important;
      }
 }
 @media (max-width: 767.98px) { 
    .checkout_section .input-group .css-b62m3t-container {
        width: 85% !important;
      }
 }

 @media (max-width: 575.98px) { 
    .checkout_section .input-group .css-b62m3t-container {
        width: 53% !important;
      }
 }

 @media (max-width: 400px) { 
    .checkout_section .input-group .css-b62m3t-container {
        width: 75% !important;
      }
 } */



 .checkout_section .accordion-button {
     padding: 10px 10px !important;
    color: white !important;
    background-color: #00c3ff !important;
 }

 .checkout_section .collapse.show{
    height: auto !important;
}

.checkout_section .accordion-button.collapsed::after {
    font-family:  "Font Awesome 5 Free" !important;
    content: "";
    width: 22px;
    height: 22px;
    display: inline-block;
    color: #fff;
    background-image: none !important;
    transform: rotate(0deg) !important; 
    
}

.checkout_section .accordion-button:after {
    font-family:  "Font Awesome 5 Free" !important;
    content: "";
    width: 22px;
    height: 22px;
    display: inline-block;
    color: #fff;
    background-image: none !important;
    transform: rotate(0deg) !important; 
    }


 .checkout_section .accordion-button:not(.collapsed) {
    color: white !important;
    background-color: #00c3ff !important;
  }

 /* your order place */
.checkout_section .checkout_payment-wrap .payment_img{
    height:25px;
    width: 35px;
}
     /* title */
.checkout_section .section-title{
    font-size: 22px;
    color: black;
    position: relative;
    font-weight: 300;
}
.checkout_section .section-title::before{
    content: "";
    position: absolute;
    top: 50%;
    right: 40%;
    background-color: #00c3ff;
    width: 100px;
    height: 3px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    -webkit-transform: translateX(50%);
    -moz-transform: translateX(50%);
    -ms-transform: translateX(50%);
    -o-transform: translateX(50%);
    transform: translateX(50%);
}

.checkout_section .section-title::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 40%;
    background-color: #00c3ff;
    width: 100px;
    height: 3px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}


@media(max-width: 416px){
    .checkout_section .section-title::before{
        right: 25%;
        width: 50px;
    }
    
    .checkout_section .section-title::after {
        left: 25%;
        width: 50px;
    
    }
}
@media(min-width: 416px) and (max-width: 576px) { 
    .checkout_section .section-title::before{
        right: 29%;
        width: 65px;
    }
    
    .checkout_section .section-title::after {
        left: 29%;
        width: 65px;
    
    }
    
    
     }
@media(min-width: 576px) and (max-width: 768px) { 
    .checkout_section .section-title::before{
        right: 30%;
        width: 70px;
    }
    
    .checkout_section .section-title::after {
        left: 30%;
        width: 70px;
    
    }
    
    
     }
@media (min-width: 768px) and (max-width: 1100px) {
.checkout_section .section-title::before{
    right: 37%;
    width: 70px;
}

.checkout_section .section-title::after {
    left: 37%;
    width: 70px;

}


 }