.brand_carousel .img_fit{
    max-height: 100%;
    width: 125px;
    object-fit: cover;
    border-radius: 2px;
}

.brand_carousel .section-title{
    color: black;
    position: relative;
    font-weight: 500;
}
.brand_carousel .section-title::before{
    content: "";
    position: absolute;
    top: 50%;
    right: 40%;
    background-color: #00c3ff;
    width: 100px;
    height: 3px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    -webkit-transform: translateX(50%);
    -moz-transform: translateX(50%);
    -ms-transform: translateX(50%);
    -o-transform: translateX(50%);
    transform: translateX(50%);
}

.brand_carousel .section-title::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 40%;
    background-color: #00c3ff;
    width: 100px;
    height: 3px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}

@media (max-width: 576px) { 
   .brand_carousel .section-title::before{
        right: 30%;
        width: 45px;
    }
    
   .brand_carousel .section-title::after {
        left: 30%;
        width: 45px;
    
    }
    
    
     }
@media(min-width: 576px) and (max-width: 768px) { 
   .brand_carousel .section-title::before{
        right: 35%;
        width: 50px;
    }
    
   .brand_carousel .section-title::after {
        left: 35%;
        width: 50px;
    
    }
    
    
     }
@media (min-width: 768px) and (max-width: 991.98px) {
.brand_carousel .section-title::before{
    right: 36%;
    width: 80px;
}

.brand_carousel .section-title::after {
    left: 35%;
    width: 80px;

}


 }